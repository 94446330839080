<template>
  <div class="list">
    <van-list
      :v-model="loading"
      :finished="finished"
      finished-text="没有更多了"
      @load="loadData"
      offset="30"
    >
      <van-row gutter="15">
        <van-col
          span="12"
          v-for="item in productlist"
          :key="item.id"
          :title="item"
          class="article-item"
        >
          <router-link :to="{path:'Product',query:{id:item.id}}">
            <div class="new-item" v-if="item.picture!=''">
              <div class="thumbpic">
                <van-image width="100%" fit="contain" :src="item.picture"></van-image>
              </div>
              <h2 class="title">{{ decodeURIComponent(item.title.replace(/\+/g, "%20"))}}</h2>
            </div>
            <div class="new-item" v-else>
              <h2 class="title">{{item.title}}</h2>
            </div>
          </router-link>
        </van-col>
      </van-row>
    </van-list>
  </div>
</template>
<script>
export default {
  name: "ProductList",
  data() {
    return {
      cateid: this.$props["categoryid"],
      CateList: [],
      loading: false,
      finished: false,
      productlist: []
    };
  },
  props: ["categoryid"],
  watch: {
    categoryid(newValue) {
      this.cateid = newValue;
      this.productlist = [];
      this.lastdataaddtime = "";
      this.loadData();
    }
  },
  methods: {
    loadData() {
      var that = this;
      var params = {
        top: 10,
        lastdataaddtime: that.lastdataaddtime,
        categoryid: this.cateid
      };
      console.log(params.categoryid);
      that.loading = true;
      that.$api.getMallData(params).then(res => {
        that.loading = false;
        if (res.data.length < params.top) {
          that.finished = true;
        }
        res.data.forEach(item => {
          that.productlist.push(item);
          that.lastdataaddtime = item.addtime;
        });
      });
    }
  }
};
</script>
  <style scoped>
.new-item {
  background: #fff;
  border-radius: 0.5rem;
  margin-bottom: 10px;
  padding: 1rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.new-item .title {
  font-size: 1.8rem;
  color: #b70903;
  font-weight: normal;
  text-align: left;
  margin: 0;
  line-height: 3rem;
  font-weight: bold;
}
.new-item a .info {
  margin: 1rem 0 0 0;
  font-size: 2.4rem;
  color: #333;
  display: flex;
  align-items: center;
}

.iteminfo {
  margin-right: 1rem;
}
.new-item .thumbpic {
  height: 12rem;
  flex: 1;
}
</style>